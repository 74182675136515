<template>
<div class="layout-nav-bar row-center-between">
  <div class="lnb-left row-center-between">
    <el-tooltip class="box-item" effect="dark" :content="currentIcon.includes('dakai')?'打开菜单':'收起菜单'" placement="bottom">
      <i :class="currentIcon" class="lnbl-icon" @click="changeMenvStatus" />
    </el-tooltip>
    <breadcrumb ref="breadRef" />
    <el-button class="lnbl-btn" type="primary" size="small" @click="goBigPage" v-if="btnShow">全屏显示</el-button>
  </div>
  <div class="lnb-right row-center-between">
    <div class="lnbr-username">{{username}}</div>
    <el-tooltip class="box-item lnbr-tooltip" effect="dark" content="退出登录" placement="bottom">
      <i class="iconfont icon-tuichu" @click="logOut" />
    </el-tooltip>
  </div>
</div>
</template>

<script>
// 全局 - 导航面包屑
import { computed, reactive, ref, toRefs } from 'vue';
import { useStore } from 'vuex';
import Breadcrumb from "../Breadcrumb";
import Cookies from "js-cookie";
import { useRouter } from 'vue-router';
export default {
  components: {
    Breadcrumb,
  },
  setup() {
    const breadRef = ref(null);
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      isCollapse: computed(() => store.getters.isCollapse),
      currentIcon: computed(() => state.isCollapse ? "iconfont icon-caidandakai" : "iconfont icon-caidanzhedie"),
      username: computed(() => {
        // 判断当前登录用户 - 区分开发环境
        if (process.env.NODE_ENV == "development") {
          return "系统用户";
        } else {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          return userInfo.name;
        }
      }),
      // 判断 只有首页时 才展示 全屏显示 按钮
      btnShow: computed(() => {
        if (breadRef.value != null) {
          if (breadRef.value.noHome == false) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      })
    });

    // 前往 大屏 页面
    const goBigPage = () => {
      router.push({ path: "/bigIndex" });
    };

    // 切换菜单开启/关闭状态
    const changeMenvStatus = () => {
      store.commit("CHANGE_MENV_STATUS");
    }

    // 退出登录
    const logOut = () => {
      Cookies.remove("code");
      localStorage.removeItem("userInfo")
      localStorage.removeItem("menuAuth")
      location.reload();
    }

    return {
      ...toRefs(state),
      changeMenvStatus,
      logOut,
      breadRef,
      goBigPage
    };
  }
}
</script>

<style lang="scss" scoped>
.layout-nav-bar {
  height: 50px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .lnb-left {
    height: 100%;

    .lnbl-icon {
      font-size: 26px;
      padding: 0 15px;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      -webkit-transition: width 0.28s;
      transition: width 0.28s;

      &:hover {
        background-color: #f9f9f9;
      }
    }

    .lnbl-btn {
      margin-left: 6px;
    }
  }

  .lnb-right {
    height: 100%;
    margin-right: 30px;

    i {
      cursor: pointer;
      font-size: 20px;
      padding: 0 10px;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}
</style>
