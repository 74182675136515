<template>
<el-container class="app-layout">
  <!-- 页面左侧 -->
  <el-aside class="layout-aside" :class="isCollapse?'layout-aside-min-width':''">
    <!-- logo展示 -->
    <logo />
    <!-- 菜单栏 -->
    <sidebar />
  </el-aside>
  <!-- 页面中间部分 -->
  <el-container class="layout-container">
    <!-- 路由导航栏 -->
    <el-header class="layout-header">
      <navbar />
      <!-- <tags-view /> -->
    </el-header>
    <!-- 页面主要内容 -->
    <el-main class="layout-main">
      <slot></slot>
    </el-main>
  </el-container>
</el-container>
</template>

<script>
// 整体布局
import { Logo, Sidebar, Navbar } from "./components";
// TagsView
import { reactive, toRefs, computed } from 'vue';
import { useStore } from 'vuex';
export default {
  components: {
    Logo,
    Sidebar,
    Navbar,
    // TagsView
  },
  setup() {
    const store = useStore();
    const state = reactive({
      isCollapse: computed(() => store.getters.isCollapse),
    });

    return toRefs(state);
  }
}
</script>

<style lang="scss" scoped>
.app-layout {
  height: 100%;
  width: 100%;

  .layout-aside {
    height: 100%;
    width: 200px;
    color: #ffffff;
    background-color: #304156;
    -webkit-box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    -webkit-transition: width 0.28s;
    transition: width 0.28s;

    // 滚动条
    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }
  }

  .layout-aside-min-width {
    width: 64px;
  }

  .layout-header {
    padding: 0;
    // height: 85px;
  }

  .layout-main {
    padding: 15px 20px;
  }
}
</style>
