<template>
<div class="login-login">
  <div class="il-top column-center-center">
    <div class="ilt-title">欢迎登录联心医疗运营管理平台</div>
    <div>一家专注于满足基层医疗市场需求，为基层医疗机构提供先进技术和运营模式的科研企业</div>
  </div>
  <div class="il-middle column-center-center">
    <!-- <div class="ilm-title">请使用钉钉进行扫码登录</div> -->
    <div class="qrCode" id="login_container">二维码部分</div>
  </div>
</div>
</template>

<script>
// 登录页
import { computed, onBeforeMount, onMounted, reactive, toRefs } from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import { useRoute } from 'vue-router';
// 登录页
export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      appKey: computed(() => store.state.app.AppKey),
      appSecret: computed(() => store.state.app.AppSecret),
      url: computed(() => store.getters.redirectUri)
    });

    // 获取 access_token
    const getAccessToken = () => {
      axios.get('/proxy/gettoken', { //你想访问的资源
        params: {
          appkey: state.appKey,
          appsecret: state.appSecret
        }
      }).then(function (response) {
        if (response.status == 200) {
          // 提交mutation，保存令牌
          store.commit("SET_ACCESS_TOKEN", response.data.access_token);
          getQrCodeImg();
        }
      }).catch(function (error) {
        console.log(error);
      });
    }

    // 获取二维码图片
    const getQrCodeImg = () => {
      let goto = encodeURIComponent(`https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${state.appKey}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${state.url}`)
      window.DDLogin({
        id: "login_container",
        goto: goto,
        style: "border:none;background-color:#FFFFFF;",
        width: "100%", //官方参数 365
        height: "300" //官方参数 400
      });
    }

    const handleMessage = (event) => {
      let origin = event.origin;
      console.log("origin", event.origin);
      if (origin == "https://login.dingtalk.com") { //判断是否来自ddLogin扫码事件。
        let loginTmpCode = event.data;
        //获取到loginTmpCode后就可以在这里构造跳转链接进行跳转了
        console.log("loginTmpCode", loginTmpCode);
        //此步拿到临时loginTmpCode换取正式code
        window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${state.appKey}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${state.url}&loginTmpCode=${loginTmpCode}`
      }
    };
    if (typeof window.addEventListener != 'undefined') {
      window.addEventListener('message', handleMessage, false);
    } else if (typeof window.attachEvent != 'undefined') {
      window.attachEvent('onmessage', handleMessage);
    }

    onBeforeMount(() => {
      window.addEventListener("message", handleMessage, false);
    })

    onMounted(() => {
      const { code } = route.query;
      console.log("userInfo", JSON.parse(localStorage.getItem("userInfo")))
      if (!code) {
        // getAccessToken();
        localStorage.removeItem("userInfo")
        localStorage.removeItem("menuAuth")
        getQrCodeImg();
      }
    })

    return {
      ...toRefs(state),
      getAccessToken,
      getQrCodeImg,
      handleMessage
    }
  }
}
</script>

<style lang="scss" scoped>
.login-login {
  height: 100%;
  background-image: url("../../assets/imgs/login-background.jpg");
  background-size: 100% 100%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .il-top {
    margin-top: 150px;

    .ilt-title {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 14px;
    }
  }

  .il-middle {
    margin-top: 30px;
    width: 500px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba($color: #b1b0b0, $alpha: 1.0);
    color: #000000;

    .ilm-title {
      text-align: center;
      margin: 14px 0 8px 0;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
</style>
