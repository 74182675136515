<template>
<el-menu-item v-if="item.children.length==0" :index="item.id">
  <i :class="item.icon" />
  <template #title>
    <!-- <span>{{item.title}}</span> -->
    <router-link :to="{path: item.path}">{{item.title}}</router-link>
  </template>
</el-menu-item>
<el-sub-menu v-else :index="item.id">
  <template #title>
    <i :class="item.icon" />
    <span>{{item.title}}</span>
  </template>
  <sidebar-item v-for="(sItem, sIndex) in item.children" :key="sIndex" :item="sItem" />
</el-sub-menu>
</template>

<script>
// 左侧菜单 - item
export default {
  name: "sidebar-item",
  props: {
    item: { type: Object },
  },
}
</script>

<style lang="scss">
.el-menu-item {
  .iconfont {
    font-size: 16px;
    margin-right: 4px;
  }
}

.el-sub-menu {
  .el-sub-menu__title {
    .iconfont {
      font-size: 16px;
      margin-right: 4px;
    }
  }
}
</style>
