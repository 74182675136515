// import Cookies from "js-cookie";

const getters = {
  isCollapse: state => state.app.isCollapse, // 菜单关闭/展开
  menuData: state => {

    if (process.env.NODE_ENV == "development") {
      return state.settings.menuData;
    } else {
      if (JSON.parse(localStorage.getItem("userInfo")) != null) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo")),
          menuData = state.settings.menuData,
          // 用户有权限的节点
          userNodes = userInfo.userNodes,
          // 数据库菜单节点
          menuNodes = userInfo.menuNodes,
          // 用户权限
          userAuth = [],
          // 首页
          result = [];

        if (userNodes.findIndex(t => t.t_dingding_page_id == 19) != -1) result.push(menuData[0]);
        // 格式化用户有权限的菜单
        for (let i = 0; i < userNodes.length; i++) {
          let tempIndex = menuNodes.findIndex(t => t.t_dingding_page_id == userNodes[i].t_dingding_page_id);
          if (tempIndex != -1) {
            userAuth.push({ txt_page_id: menuNodes[tempIndex].txt_page_id, ...userNodes[i] });
          }
        }

        // 所有子菜单合集
        let allMenuChildren = menuData.map(t => t.children).flat(Infinity);
        menuData.forEach(item => item.children = []);
        // 用户有权限的菜单 与系统菜单相结合
        for (let i = 0; i < userAuth.length; i++) {
          let tempIndex = allMenuChildren.findIndex(t => t.id == userAuth[i].txt_page_id);
          if (tempIndex != -1) {
            // 截取id 与父菜单相结合
            allMenuChildren[tempIndex] = { ...allMenuChildren[tempIndex], ...userAuth[i] };
            let splitId = userAuth[i].txt_page_id.split("-")[0];
            let parentIndex = menuData.findIndex(t => t.id == splitId);
            menuData[parentIndex].children.push(allMenuChildren[tempIndex])
          }
        }
        menuData = menuData.filter(t => t.children.length != 0);
        result = result.concat(menuData);
        // 存缓存里一份
        let menuAuth = result.map(t => t.children).flat(Infinity);
        if (result.findIndex(t => t.id == "0") != -1) menuAuth.unshift(result[0]);
        localStorage.setItem("menuAuth", JSON.stringify(menuAuth));

        return result;
      } else {
        return state.settings.menuData;
      }
    }
  },
  redirectUri: () => {
    // redirectUri: encodeURIComponent('https://system.manage.tianjinlianxin.com'), // 开发
    // redirectUri: encodeURIComponent('https://formal.system.manage.tianjinlianxin.com'), // 正式
    // redirectUri: encodeURIComponent('https://formal.system.manage.tianjinlianxin.com/withdrawal'), // 正式
    console.log(process.env)
    // if (getters.menuData.length == 1) {
    //   return process.env.VUE_APP_REDIRECT_API + "/withdrawal";
    // } else {
    return process.env.VUE_APP_REDIRECT_API
    // }
  },
  isDisabled: () => {
    if (process.env.NODE_ENV == "development") {
      return false;
    } else {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.userid == "0104580157854009" || userInfo.userid == "012422582538690434") {
        return false;
      } else {
        return true;
      }
    }
  },
  userInfo: () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    return userInfo;
  },
  // 生成随机字符串
  UUID() { return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, function () { return (Math.random() * 16 | 0).toString(16); }); },
  areas_name: () => {
    if (process.env.NODE_ENV == "development") {
      return null;
      // return "天津市,北京市";
      // return "河北省,山东省,河南省";
    } else {
      let userInfo = JSON.parse(localStorage.getItem("userInfo")),
        departmentList = userInfo.departmentList,
        department = userInfo.department,
        userid = userInfo.userid,
        // 王总 董总 闻总 安强 崔新晨 孔丹妮 吕婷
        userIds = ["030827336529376027", "012711451074634", "02612315601218637", "1840514964750801", "023017534523731916", "01166220381423111913", "012422582538690434"];
      if (userIds.findIndex(t => t == userid) > -1) {
        return null;
      } else {
        let result = [];
        for (let i = 0; i < department.length; i++) {
          let tempIndex = departmentList.findIndex(t => t.id == department[i]);
          if (tempIndex != -1) {
            result.push(departmentList[tempIndex]);
          }
        }
        return result.map(t => t.name).join(",");
      }
    }
  },
  rolesName: () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo")),
      roles = userInfo.roles || [],
      rolesName = roles.map(t => t.name);

    let subadministrator = rolesName.findIndex(t => t == "子管理员"),
      masterministrator = rolesName.findIndex(t => t == "主管"),
      masterministratorManager = rolesName.findIndex(t => t == "主管理员");
    if (subadministrator != -1) {
      rolesName.splice(subadministrator, 1);
    }
    if (masterministrator != -1) {
      rolesName.splice(masterministrator, 1);
    }
    if (masterministratorManager != -1) {
      rolesName.splice(masterministratorManager, 1);
    }

    return rolesName;
  }
};

export default getters;