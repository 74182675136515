import request from './request';
import download from './download';
import wechat from './wechat';
import formal from './formal';

// 数据驾驶舱
// 获取本月/日订单总量
export function getCntOrders(data) {
  return wechat({
		url: "/stat/cntOrders",
		method: "post",
		data
  })
}

// 获取本月/日订单总量
export function getCntTpOrder(data) {
  return wechat({
		url: "/stat/cntTpOrder",
		method: "post",
		data
  })
}

// 获取区域图数据
export function getOrdersTrend(data) {
  return wechat({
		url: "/stat/orderTrend",
		method: "post",
		data
  })
}

// 获取环形图数据
export function getStatDevice(data) {
  return wechat({
		url: "/stat/statDevice",
		method: "post",
		data
  })
}

// 获取环形图数据 - 各级合伙人业绩排行
export function getCntSubOrders(data) {
	return wechat({
		url: `/stat/cntSubOrders`,
		method: "post",
		data
  })
}

// 获取设备类型
export function getDeviceType(data) {
  return request({
    url: "/deviceTypeInfo",
    method: "post",
    data
  })
}

// 获取设备分布 - 统计图
export function getDeviceChartData(data) {
  return request({
    url: "/getDeviceDistribute",
    method: "post",
    data
  })
}

// 获取订单分布 - 统计图
export function getOrderChartData(data) {
  return request({
    url: "/getOrderStatistics",
    method: "post",
    data
  })
}

// 获取增长趋势 - 统计图
export function getGrowthChartData(data) {
  return request({
    url: "/getGrowthTrend",
    method: "post",
    data
  })
}

// 获取全部合伙人
export function getPartners(data) {
  return request({
    url: "/getPartnerInfo",
    method: "post",
    data
  })
}

// 获取合伙人详情
export function getPartnerInfoById(data) {
  return request({
    url: "/getPartnerDetail",
    method: "post",
    data
  })
}

// 合伙人签署合同
export function goSign(data) {
  return request({
    url: "/partnerSignContract",
    method: "post",
    data
  })
}

// 合伙人统计
export function partnersCount() {
  return request({
    url: "/partnerCount",
    method: "post"
  })
}

// 获取层级关系
export function getLevels(data) {
  return request({
    url: "/getSummary",
    method: "post",
    data
  })
}

// 层级关系统计
export function levelsCount() {
  return request({
    url: "/summaryCount",
    method: "post"
  })
}

// 获取地区信息
export function getAreas(data) {
  return request({
    url: "/getAreaInfo",
    method: "post",
    data
  })
}

// 筛选条件 - 合伙人
export function getPartnersSearch(data) {
  return request({
    url: "/getSubAgentNames",
    method: "post",
    data
  })
}

// 代理级别统计
export function countLevels(data) {
  return request({
    url: "/agentLevelCount",
    method: "post",
    data
  })
}

// 获取各级代理信息
export function getEveryLevelInfo(data) {
  return request({
    url: "/getLevelAgent",
    method: "post",
    data
  })
}

// 获取合同列表
export function getContracts(data) {
  return request({
    url: "/contract/getContractList",
    method: "post",
    data
  })
}

// 预览合同
export function viewContract(data) {
  return request({
    url: "/contract/viewContract",
    method: "post",
    data
  })
}

// 下载合同
export function downloadContractById(data) {
  return download({
    url: "/downloadContract",
    method: "get",
    params: data
  })
}

// 获取合同模板
export function getContractModals(data) {
  return request({
    url: "/contract/getTemplateList",
    method: "post",
    data
  })
}

// 发送合同
export function sendContract(data) {
  return request({
    url: "/contract/sendContract",
    method: "post",
    data
  })
}

// 预览模板合同
export function previewTemplateContract(data) {
  return request({
    url: "/contract/viewTemplate",
    method: "post",
    data
  })
}

// 审核
export function getAudit(data) {
  return request({
    url: "/agentVerify",
    method: "post",
    data
  })
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/admin/getUserInfo",
    method: "post",
    data
  })
}

// 提现 -- 查询账户余额
export function getBalance() {
  return request({
    url: "/getbalance",
    method: "post"
  })
}

// 提现 -- 查询明细
export function getDetailForBalance(data) {
  return request({
    url: "/accountDetails",
    method: "post",
    data
  })
} 

// 获取验证码
export function sendVerifiCode() {
  return request({
    url: "/sendsms",
    method: "post"
  })
}

// 提现 - 提交
export function submitWithdrawal(data) {
  return request({
    url: "/withdrawApply",
    method: "post",
    data
  })
}

// 获取设备订单列表
export function getEqOrderList(data) {
  return request({
    url: "/agentOrderList",
    method: "post",
    data
  })
}

// 修改设备订单状态
export function changeEqOrderStatus(data) {
  return request({
    url: "/updateStatus",
    method: "post",
    data
  })
}

// 获取设备申领订单
export function getEqOrderForList(data) {
  return request({
    url: "/getDeviceOrder",
    method: "post",
    data
  })
}

// 获取耗材申领订单
export function getCoOrderForList(data) {
  return request({
    url: "/getConsumableOrder",
    method: "post",
    data
  })
}

// 设备申领 发货
export function deliveryOrder(data) {
  return request({
    url: "/expressdelivery",
    method: "post",
    data
  })
}

// 查询符合发放奖励条件的集合
export function getDataByCondition(data) {
  return request({
    url: "/getPendingBonus",
    method: "post",
    data
  })
}

// 获取账户集余额
export function getQueryBalance(data) {
  return request({
    url: "/queryBalance",
    method: "post",
    data
  })
}

// 奖金发放
export function goPayBonus(data) {
  return request({
    url: "/payBonus",
    method: "post",
    data
  })
}

// 获取已发放过奖金的代理
export function getBonusPaidList(data) {
  return request({
    url: "/getBonusPaid",
    method: "post",
    data
  })
}

// 保证金管理 - 获取数据
export function getSecurityList(data) {
  return request({
    url: "/getAgentDeposit",
    method: "post",
    data
  })
}

// 获取省/市 选项数据，级联
export function getProCityData(data) {
  return request({
    url: "/common/getAreaByDevice",
    method: "post",
    data
  })
}

// 保证金录入
export function insertFund(data) {
  return request({
    url: "/depositPayment",
    method: "post",
    data
  })
}

// 获取已绑定设备的代理人
export function getAgentHasDevice(data) {
  return request({
    url: "/common/getAgentNamesByBound",
    method: "post",
    data
  })
}

// 设备/耗材 库存情况
export function getSituationEqAndCo(data) {
  return request({
    url: "/commodityStorageTrend",
    method: "post",
    data
  })
}

// 统计分析 - 设备分布
export function getEqDisData(data) {
  return request({
    // url: "/stat/statDeviceByType",
    url: "/stat/statDeviceTypeByArea",
    method: "post",
    data
  })
}

// 统计分析 - 设备分布 - 根据设备类型和地区统计设备
export function getEqDisByDevicetypeAndArea(data) {
  return request({
    url: "/stat/statDeviceByTypeAndArea",
    method: "post",
    data
  })
}

// 统计分析 - 设备分布 - 根据设备类型和上级代理统计设备
export function getEqDisByDevicetypeAndPagent(data) {
  return request({
    url: "/stat/statDeviceByTypeAndPagent",
    method: "post",
    data
  })
}

// 统计分析 - 订单分布 - 根据代理和时间统计订单趋势 初始 省代饼图+条形图
export function getDeviceOrderTrend(data) {
  return request({
    // url: "/stat/statLxcgOrderByPagentId",
    url: "/stat/statOrderByAgentLeveAndAreas",
    method: "post",
    data
  })
}

// 统计分析 - 订单分布 - 根据代理和时间统计订单趋势 下一步 除省代外 饼图+条形图
export function getDeviceOrderTrendExceptPro(data) {
  return request({
    url: "/stat/statOrderByTypeAndPagentId",
    method: "post",
    data
  })
}

// 统计分析 - 订单分布 - 根据代理和时间统计订单趋势 - 柱状图
export function getDeviceCrossChartData(data) {
  return request({
    url: "/stat/statOrderTrendByAgentId",
    method: "post",
    data
  })
}

// 统计分析 - 设备绑定明细
export function getDeviceBindingDetails(data) {
  return request({
    url: "/stat/deviceBindingDetails",
    method: "post",
    data
  })
}

// 设备管理 - 移交申请列表
export function getTransferList(data) {
  return request({
    url: "/device/getUnbindApply",
    method: "post",
    data
  })
}

// 设备管理 - 审批移交
export function approveTransfer(data) {
  return request({
    url: "/device/reviewApplication",
    method: "post",
    data
  })
}

// 权限管理 - 获取用户
export function getUsers(data) {
  return request({
    url: "/user/getUserList",
    method: "post",
    data
  })
}

// 权限管理 - 添加用户
export function addUserById(data) {
  return request({
    url: "/user/addDingDingUser",
    method: "post",
    data
  })
}

// 权限管理 - 获取用户权限
export function getUserAuthInfo(data) {
  return request({
    url: "/user/getUserNodes",
    method: "post",
    data
  })
}

// 权限管理 - 编辑用户权限
export function updateUserAuthInfo(data) {
  return request({
    url: "/user/saveUserNode",
    method: "post",
    data
  })
}

// 权限管理 - 删除用户
export function deleteUserAndAuth(data) {
  return request({
    url: "/user/deleteUser",
    method: "post",
    data
  })
}

// 权限管理 - 获取地区
export function getAuthRegion(data) {
  return request({
    url: "/common/getAreaData",
    method: "post",
    data
  })
}

// 分润规则 - 获取设备方案
export function getFetchTpByTypecode(data) {
  return request({
    url: "/divide/fetchTpByTypecode",
    method: "post",
    data
  })
}

// 分润规则 - 获取设备方案 - 分账比例
export function getFetchDivideConfigByRuleid(data) {
  return request({
    url: "/divide/fetchDivideConfigByRuleid",
    method: "post",
    data
  })
}

// 省代配置 - 加载省代合伙人
export function getProvincePartners() {
  return request({
    url: "/divide/fetchProvincePartners",
    method: "post"
  })
}

// 省代配置 - 加载申领和返还配置
export function getAgentConfigByAgentId(data) {
  return request({
    url: "/divide/fetchAgentConfigByAgentId",
    method: "post",
    data
  })
}

// 省代配置 - 加载适用分润规则
export function getDivideConfigByAgentId(data) {
  return request({
    url: "/divide/fetchDivideConfigByAgentId",
    method: "post",
    data
  })
}

// 省代配置 - 根据ID查询 联检返还 分润规则
// 已废弃
export function getSubDivideRuleById(data) {
  return request({
    url: "/divide/fetchSubDivideRuleById",
    method: "post",
    data
  })
}

// 省代配置 - 根据ID查询 分润规则 + 配置
export function getDivideRuleById(data) {
  return request({
    url: "/divide/fetchDivideRuleById",
    method: "post",
    data
  })
}

// 省代配置 - 新增代理配置
export function createAgentConfig(data) {
  return request({
    url: "/divide/insertAgentConfig",
    method: "post",
    data
  })
}

// 省代配置 - 更新代理配置
export function updateAgentConfig(data) {
  return request({
    url: "/divide/updateAgentConfig",
    method: "post",
    data
  })
}

// 省代配置 - 删除代理配置
export function deleteAgentConfig(data) {
  return request({
    url: "/divide/deleteAgentConfig",
    method: "post",
    data
  })
}

// 省代配置 - 默认情况下 - 新增 分润规则
export function createDevideRuleById(data) {
  return request({
    url: "/divide/copyAndInsertDevideRuleById",
    method: "post",
    data
  })
}

// 省代配置 - 个人规则 更新 分润规则
export function updatetDivideConfigByRuleId(data) {
  return request({
    url: "/divide/updatetDivideConfigByRuleIid",
    method: "post",
    data
  })
}

// 省代配置 - 删除 分润规则
export function deleteTpAndDivide(data) {
  return request({
    url: "/divide/deleteTpAndDivideByRuleId",
    method: "post",
    data
  })
}

// 创建出库单 - 获取列表
export function getWareHouseOrderList(data) {
  return request({
    url: "/offlineOrder/orderList",
    method: "post",
    data
  })
}

// 创建出库单 - 获取商品列表 设备D/耗材C
export function getGoodsListByType(data) {
  return request({
    url: "/offlineOrder/goodsListByType",
    method: "post",
    data
  })
}

// 创建出库单 - 确认申领
export function createWareHouseOrder(data) {
  return request({
    url: "/offlineOrder/createOrder",
    method: "post",
    data
  })
}

// 创建出库单/ 创建入库单 - 取消订单
export function cancelOrder(data) {
  return request({
    url: "/common/clpsordercancel",
    method: "post",
    data
  })
}

// 创建出库单 - 确认申领
export function wareHouseOrderDetail(data) {
  return request({
    url: "/offlineOrder/orderDetails",
    method: "post",
    data
  })
}

// 创建入库单 - 获取列表数据
export function getPutInOrderList(data) {
  return request({
    url: "/warehousing/orderList",
    method: "post",
    data
  })
}

// 创建入库单 - 获取仓库和供应商
export function getSupplierInfoAndwareHousInfo(data) {
  return request({
    url: "/common/getSupplierInfoAndwareHousInfo",
    method: "post",
    data
  })
}

// 创建入库单 - 获取商品信息
export function getGoodsInfo(data) {
  return request({
    url: "/warehousing/getGoodsBaseInfo",
    method: "post",
    data
  })
}

// 创建入库单 - 创建入库单
export function createPIOrder(data) {
  return request({
    url: "/warehousing/addPoOrder",
    method: "post",
    data
  })
}

// 财务管理 - 设备统计
export function deviceStatis(data) {
  return request({
    url: "/financeFetchDevice",
    method: "post",
    data
  })
}

// 财务管理 - 按设备/或不按设备  统计订单
export function getDeviceCashflow(data) {
  return request({
    url: "/financeFetchDeviceCashflow",
    method: "post",
    data
  })
}

// 财务管理 - 诊所统计 获取列表
export function getClinicStatisData(data) {
  return request({
    url: "/statDW",
    method: "post",
    data
  })
}

// 公用 - 获取系统内开始和结束时间
export function getSystemTime(data) {
  return request({
    url: "/common/getSystemTime",
    method: "post",
    data
  })
}

// 公用 - 获取全部代理信息（树）
export function getAllAgent(data) {
  return request({
    url: "/common/getAgentAllByListOrTree",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 根据 代理ID 获取设备代理情况
export function getAgentDeviceByAgentId(data) {
  return request({
    url: "/device/getAgentDeviceByAgentId",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 获取代理基础信息
export function getAgentBaseInfo(data) {
  return request({
    url: "/device/agentBaseInfo",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 用户禁用
export function disableAgent(data) {
  return request({
    url: "/device/disableAgentById",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 根据代理ID获取可替换的代理
export function getReplaceableAgent(data) {
  return request({
    url: "/device/fetchReplaceableAgent",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 更具当前设备代理 旧换新
export function goReplaceableAgent(data) {
  return request({
    url: "/device/replaceAgent",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 获取当前代理可更换的上级代理
export function getReplacePagent(data) {
  return request({
    url: "/device/fetchReplaceablePagent",
    method: "post",
    data
  })
}

// 合伙人关系变更 - 更换上级代理
export function goReplaceablePagent(data) {
  return request({
    url: "/device/replacePagent",
    method: "post",
    data
  })
}

// 诊所负责人管理 - 资质图片回显
export function getClinicQualification(data) {
  return request({
    url: "/common/clinicQualification",
    method: "post",
    data
  })
}

// 诊所负责人管理 - 获取列表
export function getClinicAgentList(data) {
  return request({
    url: "/getClinicAgent",
    method: "post",
    data
  })
}

// 诊所账号管理 - 获取列表
export function getClinicUserList(data) {
  return request({
    url: "/clinicManage/getClinicUserList",
    method: "post",
    data
  })
}

// 诊所账号管理 - 获取统计
export function getStatCount(data) {
  return request({
    url: "/clinicManage/getStatCount",
    method: "post",
    data
  })
}

// 诊所账号管理 - 重置密码
export function resetPwd(data) {
  return request({
    url: "/clinicManage/resetPwd",
    method: "post",
    data
  })
}

// 合伙人管理 - 获取代理详情信息（新）
export function getAgentDetail(data) {
  return request({
    url: "/getAgentDetail",
    method: "post",
    data
  })
}

// 诊所负责人管理 - 获取合同列表
export function getAgentContractList(data) {
  return request({
    url: "/getAgentContractList",
    method: "post",
    data
  })
}

// 统计分析 - 获取订单排行列表
export function getOrderRankList(data) {
  return request({
    // url: "/fetchBonusIots", 已废弃
    url: "/stat/ordersRank",
    method: "post",
    data
  })
}

// 统计分析 - 装机量统计
export function getInstallAmount(data) {
  return request({
    url: "/stat/cntdevicesByArea",
    method: "post",
    data
  })
}

// 设备管理 - 设备解绑 - 根据IOT获取设备
export function deviceGetUnbind(data) {
  return request({
    url: "/device/getClinicDevice",
    method: "post",
    data
  })
}

// 设备管理 - 设备解绑 - 解绑
export function deviceGoUnbind(data) {
  return request({
    url: "/device/unbindDevice",
    method: "post",
    data
  })
}

// 批次管理 - 获取检测类型
export function getCategoryList(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_category/get_list",
    method: "post",
    data
  })
}

// 批次管理 - 获取批次列表
export function getBatchList(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_batch/get_page",
    method: "post",
    data
  })
}

// 批次管理 - 获取供应商列表 option
export function getManufacturerList(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_manufacturer/get_list",
    method: "post",
    data
  })
}

// 批次管理 - 新增批次
export function addBatch(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_batch/add",
    method: "post",
    data
  })
}

// 批次管理 - 根据 ID 获取批次详情
export function getBatchDetail(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_batch/get_detail",
    method: "post",
    data
  })
}

// 批次管理 - 编辑批次
export function updateBatch(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_batch/update",
    method: "post",
    data
  })
}

// 批次管理 - 获取 曲线列表
export function getCurveList(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve/get_list",
    method: "post",
    data
  })
}

// 批次管理 - 获取 曲线组 option
export function getCurveVersionList(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/get_list",
    method: "post",
    data
  })
}

// 批次管理 - 新增 曲线配置
export function addCurve(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve/add",
    method: "post",
    data
  })
}

// 批次管理 - 编辑 曲线配置
export function updateCurve(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve/update",
    method: "post",
    data
  })
}

// 批次管理 - 删除 曲线配置
export function deleteCurve(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve/delete",
    method: "post",
    data
  })
}

// 批次管理 - 删除 批次
export function deleteBatch(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_batch/delete",
    method: "post",
    data
  })
}

// 曲线组管理 - 获取列表(分页)
export function getCurveListByPage(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/get_page",
    method: "post",
    data
  })
}

// 曲线组管理 - 新增
export function addCurveVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/add",
    method: "post",
    data
  })
}

// 曲线组管理 - 根据 ID 获取详情
export function getCurveVersionDetail(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/get_detail",
    method: "post",
    data
  })
}

// 曲线组管理 - 编辑
export function editCurveVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/update",
    method: "post",
    data
  })
}

// 曲线组管理 - 删除
export function deleteCurveVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_curve_version/delete",
    method: "post",
    data
  })
}

// 胶体金版本管理 - 获取列表(分页)
export function getLxcgVersionPage(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_hardware_version/get_page",
    method: "post",
    data
  })
}

// 胶体金版本管理 - 新增
export function addLxcgVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_hardware_version/add",
    method: "post",
    data
  })
}

// 胶体金版本管理 - 根据 ID 获取详情
export function getLxcgVersionDetail(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_hardware_version/get_detail",
    method: "post",
    data
  })
}

// 胶体金版本管理 - 编辑
export function updateLxcgVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_hardware_version/update",
    method: "post",
    data
  })
}

// 胶体金版本管理 - 删除
export function deleteLxcgVersion(data) {
  return formal({
    url: "apis/admin/api/v1/lxcg_hardware_version/delete",
    method: "post",
    data
  })
}

// 补丁程序管理 - 获取列表（分页）
export function getPatchListInPage(data) {
  return formal({
    url: "apis/admin/api/v1/patch/get_page",
    method: "post",
    data
  })
}

// 补丁程序管理 - 新增
export function addPatch(data) {
  return formal({
    url: "apis/admin/api/v1/patch/add",
    method: "post",
    data
  })
}

// 补丁程序管理 - 获取详情
export function getPatchDetail(data) {
  return formal({
    url: "apis/admin/api/v1/patch/get_detail",
    method: "post",
    data
  })
}

// 补丁程序管理 - 编辑
export function updatePatch(data) {
  return formal({
    url: "apis/admin/api/v1/patch/update",
    method: "post",
    data
  })
}

// 补丁程序管理 - 删除
export function deletePatch(data) {
  return formal({
    url: "apis/admin/api/v1/patch/delete",
    method: "post",
    data
  })
}

// 获取设备类型 option
export function getDeviceTypeList(data) {
  return formal({
    url: "apis/admin/api/v1/device_type/get_list",
    method: "post",
    data
  })
}

// 获取临时 OSS 参数
export function getOSSParams(data) {
  return formal({
    url: "apis/admin/api/v1/oss/get_oss_sts_params",
    method: "post",
    data
  })
}