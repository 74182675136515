const state = {
  systemTitle: "联心医疗管理运营平台",
  menuData: [
    { id: "0", title: "工作台", show: true, icon: "iconfont icon-shouye", path: "/", children: [] },
    {
      id: "1", title: "合伙人管理", show: true, icon: "iconfont icon-hehuoren", children: [
        { id: "1-0", title: "我的合伙人", icon: "", path: "/myPartners", children: [] },
        { id: "1-1", title: "层级关系概览", icon: "", path: "/hierarchy", children: [] },
        { id: "1-2", title: "省级合伙人管理", icon: "", path: "/province", children: [] },
        { id: "1-3", title: "市级合伙人管理", icon: "", path: "/city", children: [] },
        { id: "1-4", title: "市场专员管理", icon: "", path: "/market", children: [] },
        { id: "1-5", title: "诊所负责人管理", icon: "", path: "/clinic", children: [] },
        { id: "1-6", title: "诊所账号管理", icon: "", path: "/clinicId", children: [] },
        { id: "1-7", title: "合伙人关系变更", icon: "", path: "/unbind", children: [] },
      ]
    },
    {
      id: "2", title: "财务管理", show: true, icon: "iconfont icon-caiwuguanli", children: [
        { id: "2-0", title: "提现", icon: "", path: "/withdrawal", children: [] },
        { id: "2-1", title: "设备申领", icon: "", path: "/equipmentFor", children: [] },
        { id: "2-2", title: "诊所统计", icon: "", path: "/clinicStatic", children: [] },
        { id: "2-3", title: "设备统计", icon: "", path: "/equipmentStatic", children: [] },
      ]
    },
    {
      id: "3", title: "订单管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "3-0", title: "设备申领订单", icon: "", path: "/equipmentOrder", children: [] },
        { id: "3-1", title: "耗材申领订单", icon: "", path: "/consumableOrder", children: [] },
      ]
    },
    {
      id: "4", title: "总账管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "4-0", title: "保证金", icon: "", path: "/securityFund", children: [] },
        { id: "4-1", title: "销售激励", icon: "", path: "/incentive", children: [] },
        { id: "4-2", title: "分润规则", icon: "", path: "/shareRule", children: [] },
        { id: "4-3", title: "省代配置", icon: "", path: "/proConfig", children: [] },
      ]
    },
    {
      id: "5", title: "库存管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "5-0", title: "库存预警", icon: "", path: "/inventory", children: [] },
        { id: "5-1", title: "创建出库单", icon: "", path: "/warehouseReceipt", children: [] },
        { id: "5-2", title: "创建入库单", icon: "", path: "/putInStorage", children: [] },
      ]
    },
    {
      id: "6", title: "统计分析", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "6-0", title: "设备分布", icon: "", path: "/eqDistribution", children: [] },
        { id: "6-1", title: "订单数量统计", icon: "", path: "/orderDistribution", children: [] },
        { id: "6-2", title: "订单金额统计", icon: "", path: "/orderDistributionByAmount", children: [] },
        { id: "6-3", title: "设备绑定明细", icon: "", path: "/deviceBindDetail", children: [] },
        { id: "6-4", title: "订单排行", icon: "", path: "/ordersRank", children: [] },
        { id: "6-5", title: "装机量", icon: "", path: "/installAmount", children: [] },
        { id: "6-6", title: "省级数据驾驶舱", icon: "", path: "/dataCockpit", children: [] },
      ]
    },
    {
      id: "7", title: "设备管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "7-0", title: "移交申请管理", icon: "", path: "/transfer", children: [] },
        { id: "7-1", title: "设备解绑", icon: "", path: "/deviceUnbind", children: [] },
      ]
    },
    {
      id: "8", title: "权限管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "8-0", title: "权限管理", icon: "", path: "/authority", children: [] },
      ]
    },
    {
      id: "9", title: "运营管理", show: true, icon: "iconfont icon-dingdanguanli", children: [
        { id: "9-0", title: "批次管理", icon: "", path: "/batchMa", children: [] },
        { id: "9-1", title: "曲线组管理", icon: "", path: "/curveMa", children: [] },
        { id: "9-2", title: "胶体金版本管理", icon: "", path: "/lxcgVersionMa", children: [] },
        { id: "9-3", title: "补丁程序管理", icon: "", path: "/patchMa", children: [] },
      ]
    },
  ],
  permit: {
    // 全部权限 闻滨 董巍 禇志博 崔新晨 吕婷 安强
    all: ["02612315601218637", "012711451074634", "174618690334511101", "023017534523731916", "012422582538690434", "1840514964750801"],
    // 财务管理权限 李薇
    financial: ["0104580157-1576583642"]
  }
}

export default {
  state
};