const state = {
  checkedRoutes: [{ title: "首页", path: "/" }]
}

const mutations = {
  // 已废弃：当前被选中的菜单，缓存
  SET_ROUTES_CHECKED: (state, param) => {
    let index = state.checkedRoutes.findIndex(t => t.path == param.path);
    if (index == -1) {
      state.checkedRoutes.push(param);
    }
  },
  // 已废弃：格式化选中的菜单
  SPLICE_ROUTES_CHECKED: (state, param) => {
    state.checkedRoutes.splice(param, 1);
    let length = state.checkedRoutes.length,
      newCurrentRoute = state.checkedRoutes[length - 1];
    // console.log(window)
    window.router.push({ path: newCurrentRoute.path });
  },
  // 已废弃：刷新路由，回归至首页
  REFRESH_ROUTES_CHECKED: (state) => {
    state.checkedRoutes = [{ title: "首页", path: "/" }];
  }
}

export default {
  state, mutations
}