import { createStore } from 'vuex';
import app from "./modules/app";
import settings from "./modules/settings";
import getters from "./getters";
import tags from "./modules/tags";


export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    app,
    settings,
    tags
  },
  getters
})
