<template>
<div class="sidebar-logo-part">
  <!-- 菜单展开时 -->
  <template v-if="isCollapse">
    <div class="sidebar-only-logo row-center-center">
      <img src="../../../assets/logo.png">
    </div>
  </template>
  <template v-else>
    <div class="sidebar-title row-center-center">
      <img src="../../../assets/logo.png">
      <span>{{settings.systemTitle}}</span>
    </div>
  </template>
</div>
</template>

<script>
// 左侧菜单部分 - 顶部logo
import { computed, reactive, toRefs } from "vue";
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    const state = reactive({
      isCollapse: computed(() => store.getters.isCollapse),
      settings: computed(() => store.state.settings)
    });

    return toRefs(state);
  }
}
</script>

<style lang="scss" scoped>
.sidebar-logo-part {
  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 15px;
  }

  .sidebar-only-logo {
    height: 50px;
  }

  .sidebar-title {
    height: 50px;

    span {
      -webkit-transition: width 0.28s;
      transition: width 0.28s;
      font-weight: bold;
      font-size: 14px;
    }
  }
}
</style>
