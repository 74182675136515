<template>
<el-breadcrumb class="layout-breadcrumb" spellcheck="/">
<!-- 判断是否是首页 -->
  <template v-if="noHome">
    <el-breadcrumb-item v-for="(item, index) in breadData" :key="index">
      {{item.title}}
    </el-breadcrumb-item>
  </template>
  <template v-else>
    <el-breadcrumb-item>首页</el-breadcrumb-item>
  </template>
</el-breadcrumb>
</template>

<script>
// 面包屑
import { reactive, toRefs, watch } from 'vue';
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    const state = reactive({
      breadData: [],
      noHome: false
    });

    // 监听路由
    watch(() => router.currentRoute.value, (newValue) => {
      if(newValue.path != "/login" && newValue.path != "/bigIndex" && newValue.path != "/401") {
        if (newValue.path == "/") {
          state.noHome = false;
        } else {
          // 父菜单数组
          let breadHeader = [
            { key: "partner", value: "合伙人管理" },
            { key: "financial", value: "财务管理" },
            { key: "order", value: "订单管理" },
            { key: "ledger", value: "总账管理" },
            { key: "repertory", value: "库存管理" },
            { key: "statistic", value: "统计分析" },
            { key: "equipmentMa", value: "设备管理" },
            { key: "authority", value: "权限管理" },
            { key: "operate", value: "运营管理" },
          ],
          // 寻找当前路由的父菜单
          targetIndex = breadHeader.findIndex(t => t.key == newValue.meta.father);
          // 定义面包屑样式
          state.breadData = [
            { title: breadHeader[targetIndex].value },
            { title: newValue.meta.title }
          ];
          state.noHome = true;
        }
      }
    }, { immediate: true })

    return toRefs(state);
  }
}
</script>

<style lang="scss">
.layout-breadcrumb {
  font-size: 16px;

  .el-breadcrumb__item {
    &:first-child {
      .el-breadcrumb__inner {
        font-weight: bold;
      }
    }
  }
}
</style>
