
// 项目基本
const state = {
  isCollapse: false, // 菜单关闭/展开
  AgentId: "3280300365", // " 1666059590"   3280300365
  AppKey: "dingkvwb2ye1dv2w1ao6", // dingkz9ksda059v841z5    dingkvwb2ye1dv2w1ao6
  AppSecret: "vu3KRd2ES4i-To6TbpMC89s9k7E01HhOTToISnGShB6nhkEyxWt2WXoyPtf3niRm",
  // MfmdZ17Nmz-4mLD_D_Q3MyFNig45qaXEa-L3hLXjo67B_WeXnGeFaft7bEwgmsPb
  // vu3KRd2ES4i-To6TbpMC89s9k7E01HhOTToISnGShB6nhkEyxWt2WXoyPtf3niRm
  Access_token: "",
  redirectUri: encodeURIComponent('https://formal.system.manage.tianjinlianxin.com'), // 开发
}

const mutations = {
  // 切换菜单开启/关闭状态
  CHANGE_MENV_STATUS: state => {
    state.isCollapse = !state.isCollapse;
  },
  // 设置登录令牌
  SET_ACCESS_TOKEN: (state, param) => {
    state.Access_token = param;
  }
}

export default {
  state,
  mutations
}