<template>
<div class="no-auth-page column-center-center">
  <div class="nap-main row-center-center">
    <div class="napm-left">
      <div class="napml-top row-center-center">
        <img src="../../assets/logo.png" />
        <span>401</span>
      </div>
      <div class="napml-text">您未被授权，没有查看权限，请联系管理员</div>
      <div class="nap-btns">
        <el-button type="primary" @click="goBackLoginPage">返回登录页</el-button>
        <el-button :disabled="btnDisabled" @click="goBackPreviousPage">返回系统</el-button>
      </div>
    </div>
    <img class="napm-big-img" src="../../assets/imgs/401.png" />
  </div>
</div>
</template>

<script>
// 401 页面
import { useRouter } from 'vue-router';
import Cookies from "js-cookie";
import { computed, reactive, toRefs } from 'vue';
export default {
  setup() {
    const state = reactive({
      btnDisabled: computed(() => {
        let code = Cookies.get("code");
        if (code != undefined) {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          if (userInfo) {
            if ((userInfo.userNodes || []).length == 0) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
    });
    const router = useRouter();
    // 返回登录页
    const goBackLoginPage = () => {
      Cookies.remove("code");
      localStorage.removeItem("userInfo")
      router.push({ path: "/login" });
    };

    // 返回系统
    const goBackPreviousPage = () => {
      let menuAuth = JSON.parse(localStorage.getItem("menuAuth"));
      router.push({ path: menuAuth[0].path });
    }

    return {
      goBackLoginPage,
      goBackPreviousPage,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-auth-page {
  height: 100%;
  width: 100%;

  .nap-main {
    width: 100%;

    .napm-left {
      margin-right: 60px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .napml-top {
        span {
          font-size: 80px;
          color: #cdd2d7;
          margin-left: 10px;
        }

        img {
          height: 40px;
          border-radius: 30px;
          box-shadow: 0 1px 4px rgba(0, 21, 41, 0.6);
        }
      }

      .napml-text {
        font-size: 18px;
        margin-top: 15px;
      }

      .el-button {
        margin-top: 10px;
      }
    }

    .napm-big-img {
      height: 500px;
      animation: jumpBoxHandler 1.8s infinite;
      /* 1.8s 事件完成时间周期 infinite无限循环 */
    }

    @keyframes jumpBoxHandler {

      /* css事件 */
      0% {
        transform: translate(0px, 0px);
      }

      50% {
        transform: translate(0px, 30px);
        /* 可配置跳动方向 */
      }

      100% {
        transform: translate(0px, 0px);
      }
    }
  }
}
</style>
