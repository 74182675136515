import axios from "axios";


axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_WECHAT_API,
  timeout: 60000
});

// request 拦截器
service.interceptors.request.use(config => {
  return config;
}, err => {
  console.error(err);
  Promise.reject(err);
});

// 响应拦截器
service.interceptors.response.use(res => {
  return res.data;
}, err => {
  console.error(err);
  Promise.reject(err);
});

export default service;