import { createRouter, createWebHistory } from 'vue-router';
import Cookies from "js-cookie";
import { getUserInfo } from "api/apis.js";
import { ElMessage } from 'element-plus';

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ 'views/login/index.vue'),
    meta: { title: "登录" }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ 'views/home/index.vue'),
    meta: { title: "首页" }
  },
  {
    path: "/myPartners",
    name: "MyPartners",
    component: () => import(/* webpackChunkName: "myPartners" */ 'views/myPartners/index.vue'),
    meta: { title: "我的合伙人", father: "partner" }
  },
  {
    path: "/unbind",
    name: "Unbind",
    component: () => import(/* webpackChunkName: "unbind" */ 'views/myPartners/unbind.vue'),
    meta: { title: "合伙人关系变更", father: "partner" }
  },
  {
    path: "/hierarchy",
    name: "Hierarchy",
    component: () => import(/* webpackChunkName: "hierarchy" */ 'views/hierarchy/index.vue'),
    meta: { title: "层级关系概览", father: "partner" }
  },
  {
    path: "/province",
    name: "ProvinceMana",
    component: () => import(/* webpackChunkName: "province" */ 'views/province/index.vue'),
    meta: { title: "省级合伙人管理", father: "partner" }
  },
  {
    path: "/city",
    name: "CityMana",
    component: () => import(/* webpackChunkName: "province" */ 'views/city/index.vue'),
    meta: { title: "市级合伙人管理", father: "partner" }
  },
  {
    path: "/market",
    name: "MarketMana",
    component: () => import(/* webpackChunkName: "market" */ 'views/market/index.vue'),
    meta: { title: "市场专员管理", father: "partner" }
  },
  {
    path: "/clinic",
    name: "ClinicMana",
    component: () => import(/* webpackChunkName: "clinic" */ 'views/clinic/index.vue'),
    meta: { title: "诊所负责人管理", father: "partner" }
  },
  {
    path: "/clinicId",
    name: "ClinicIdMana",
    component: () => import(/* webpackChunkName: "ClinicIdMana" */ 'views/clinic/accountMa.vue'),
    meta: { title: "诊所账号管理", father: "partner" }
  },
  {
    path: "/withdrawal",
    name: "Withdrawal",
    component: () => import(/* webpackChunkName: "withdrawal" */ 'views/withdrawal/index.vue'),
    meta: { title: "提现", father: "financial" }
  },
  {
    path: "/equipmentFor",
    name: "EquipmentFor",
    component: () => import(/* webpackChunkName: "equipmentFor" */ 'views/equipmentFor/index.vue'),
    meta: { title: "设备申领", father: "financial" }
  },
  {
    path: "/clinicStatic",
    name: "ClinicStatic",
    component: () => import(/* webpackChunkName: "clinicStatic" */ 'views/finance/clinicStatic.vue'),
    meta: { title: "诊所统计", father: "financial" }
  },
  {
    path: "/equipmentStatic",
    name: "EquipmentStatic",
    component: () => import(/* webpackChunkName: "equipmentStatic" */ 'views/finance/equipmentStatic.vue'),
    meta: { title: "设备统计", father: "financial" }
  },
  {
    path: "/equipmentOrder",
    name: "EquipmentOrder",
    component: () => import(/* webpackChunkName: "equipmentOrder" */ 'views/order/equipment.vue'),
    meta: { title: "设备申领订单", father: "order" }
  },
  {
    path: "/consumableOrder",
    name: "ConsumableOrder",
    component: () => import(/* webpackChunkName: "consumableOrder" */ 'views/order/consumable.vue'),
    meta: { title: "耗材申领订单", father: "order" }
  },
  {
    path: "/securityFund",
    name: "SecurityFund",
    component: () => import(/* webpackChunkName: "SecurityFund" */ 'views/ledger/securityFund.vue'),
    meta: { title: "保证金", father: "ledger" }
  },
  {
    path: "/incentive",
    name: "Incentive",
    component: () => import(/* webpackChunkName: "Incentive" */ 'views/ledger/incentive.vue'),
    meta: { title: "销售激励", father: "ledger" }
  },
  {
    path: "/shareRule",
    name: "ShareRule",
    component: () => import(/* webpackChunkName: "ShareRule" */ 'views/ledger/shareRule.vue'),
    meta: { title: "分润规则", father: "ledger" }
  },
  {
    path: "/proConfig",
    name: "ProConfig",
    component: () => import(/* webpackChunkName: "ProConfig" */ 'views/ledger/proConfig.vue'),
    meta: { title: "省代配置", father: "ledger" }
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: () => import(/* webpackChunkName: "Inventory" */ 'views/repertory/inventory.vue'),
    meta: { title: "库存预警", father: "repertory" }
  },
  {
    path: "/warehouseReceipt",
    name: "WarehouseReceipt",
    component: () => import(/* webpackChunkName: "WarehouseReceipt" */ 'views/repertory/warehouseReceipt.vue'),
    meta: { title: "创建出库单", father: "repertory" }
  },
  {
    path: "/putInStorage",
    name: "PutInStorage",
    component: () => import(/* webpackChunkName: "PutInStorage" */ 'views/repertory/putInStorage.vue'),
    meta: { title: "创建入库单", father: "repertory" }
  },
  {
    path: "/eqDistribution",
    name: "EqDistribution",
    component: () => import(/* webpackChunkName: "EqDistribution" */ 'views/statistic/eqDistribution.vue'),
    meta: { title: "设备分布", father: "statistic" }
  },
  {
    path: "/dataCockpit",
    name: "DataCockpit",
    component: () => import(/* webpackChunkName: "DataCockpit" */ 'views/statistic/dataCockpit.vue'),
    meta: { title: "省级数据驾驶舱", father: "statistic" }
  },
  {
    path: "/orderDistribution",
    name: "OrderDistribution",
    component: () => import(/* webpackChunkName: "OrderDistribution" */ 'views/statistic/orderDistribution.vue'),
    meta: { title: "订单数量统计", father: "statistic" }
  },
  {
    path: "/orderDistributionByAmount",
    name: "OrderDistributionByAmount",
    component: () => import(/* webpackChunkName: "OrderDistributionByAmount" */ 'views/statistic/orderDistributionByAmount.vue'),
    meta: { title: "订单金额统计", father: "statistic" }
  },
  {
    path: "/deviceBindDetail",
    name: "DeviceBindDetail",
    component: () => import(/* webpackChunkName: "DeviceBindDetail" */ 'views/statistic/deviceBindDetail.vue'),
    meta: { title: "设备绑定明细", father: "statistic" }
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: () => import(/* webpackChunkName: "transfer" */ 'views/equipmentMa/transfer.vue'),
    meta: { title: "移交申请管理", father: "equipmentMa" }
  },
  {
    path: "/authority",
    name: "Authority",
    component: () => import(/* webpackChunkName: "authority" */ 'views/authority/authority.vue'),
    meta: { title: "权限管理", father: "authority" }
  },
  {
    path: "/bigIndex",
    name: "BigIndex",
    component: () => import(/* webpackChunkName: "bigIndex" */ 'views/home/bigIndex.vue'),
    meta: { title: "大屏" }
  },
  {
    path: "/401",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "notFound" */ 'views/home/notFound.vue'),
    meta: { title: "大屏" }
  },
  {
    path: "/ordersRank",
    name: "OrdersRank",
    component: () => import(/* webpackChunkName: "OrdersRank" */ 'views/statistic/orders.vue'),
    meta: { title: "订单排行", father: "statistic" }
  },
  {
    path: "/installAmount",
    name: "InstallAmount",
    component: () => import(/* webpackChunkName: "InstallAmount" */ 'views/statistic/installAmount.vue'),
    meta: { title: "装机量", father: "statistic" }
  },
  {
    path: "/deviceUnbind",
    name: "DeviceUnbind",
    component: () => import(/* webpackChunkName: "DeviceUnbind" */ 'views/equipmentMa/deviceUnbind.vue'),
    meta: { title: "设备解绑", father: "equipmentMa" }
  },
  {
    path: "/batchMa",
    name: "BatchMa",
    component: () => import(/* webpackChunkName: "BatchMa" */ 'views/operate/batchMa.vue'),
    meta: { title: "批次管理", father: "operate" }
  },
  {
    path: "/curveMa",
    name: "CurveMa",
    component: () => import(/* webpackChunkName: "CurveMa" */ 'views/operate/curveMa.vue'),
    meta: { title: "曲线组管理", father: "operate" }
  },
  {
    path: "/lxcgVersionMa",
    name: "LxcgVersionMa",
    component: () => import(/* webpackChunkName: "LxcgVersionMa" */ 'views/operate/lxcgVersionMa.vue'),
    meta: { title: "胶体金版本管理", father: "operate" }
  },
  {
    path: "/patchMa",
    name: "PatchMa",
    component: () => import(/* webpackChunkName: "PatchMa" */ 'views/operate/patchMa.vue'),
    meta: { title: "补丁程序管理", father: "operate" }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// 路由导航首页
router.beforeEach((to, from, next) => {
  // 跳过开发环境
  if (process.env.NODE_ENV == "development") {
    next();
  } else {
    // 判断是否存在登录令牌
    let code = Cookies.get("code");
    if (code != undefined) {
      let menuAuth = JSON.parse(localStorage.getItem("menuAuth"));
      if (to.path != "/401" && to.path != "/login") {
        if (to.path != "/bigIndex") {
          let tempIndex = menuAuth.findIndex(t => t.path == to.path);
          if (tempIndex != -1) {
            next();
          } else {
            next("/401");
          }
        } else {
          let tempIndex = menuAuth.findIndex(t => t.path == "/");
          if (tempIndex != -1) {
            next();
          } else {
            next("/401");
          }
        }
      } else {
        next();
      }
    } else {
      if (to.query.code != undefined) {
        // 获取用户信息
        getUserInfo({ code: to.query.code }).then(response => {
          if (response.code == 200) {
            if (response.data) {
              // 只要有用户信息，就能登录进来
              Cookies.set("userInfo", JSON.stringify(response.data));
              localStorage.setItem("userInfo", JSON.stringify(response.data));
              Cookies.set("code", to.query.code, { expires: new Date(new Date().getTime() + 60 * 1000 * 60) });
              if ((response.data.userNodes || []).length == 0) {
                next("/401");
              } else {
                next();
              }
            } else {
              ElMessage.error("您没有该平台权限，请联系管理员");
            }
          } else {
            ElMessage.error("登录信息已失效，请重新登录");
            // 清空登录信息
            setTimeout(() => {
              to.query = new Object();
              Cookies.remove("code");
              next("/");
            }, 1500)
          }
        })
      } else {
        if (to.path == "/login") {
          next();
        } else {
          Cookies.remove("code");
          router.push({ name: "Login" });
          // router.replace({ name: "Login" });
          // location.reload();
        }
      }
    }
  }
})

window.router = router;

export default router
