<template>
<div class="big-index-page">
  <iframe src="../common/index.html" style="width: 100%;height: 100%;" frameborder="0" />
</div>
</template>

<script>
// 首页大屏 全屏模式
export default {
  setup() {

    return {}
  }
}
</script>

<style lang="scss" scoped>
.big-index-page {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
