<template>
<div class="layout-tags-view column-start-center">
  <div class="tags-view-item row-center-start">
    <router-link class="layout-router-link tags-view-item row-center-center" v-for="(tag, index) in visitedViews" ref="tag" :key="index" :class="isActive(tag)?'active':''" :to="{ path: tag.path, fullPath: tag.fullPath }" tag="span" :style="activeStyle(tag)">
      {{ tag.title }}
      <el-icon v-if="isActive(tag) && tag.path!='/'" @click="closeMenu(tag, index)">
        <Close />
      </el-icon>
    </router-link>
  </div>
</div>
</template>

<script>
// 导航标签
import { computed, reactive, toRefs } from 'vue';
import { useStore } from "vuex";
import { useRoute,  } from 'vue-router';
// useRouter
import { Close } from "@element-plus/icons-vue";
export default {
  components: { Close },
  setup() {
    const store = useStore();
    const route = useRoute();
    // const router = useRouter();
    const state = reactive({
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      affixTags: [],
      visitedViews: computed(() => store.state.tags.checkedRoutes),
    });

    // 菜单活跃状态
    const isActive = (param) => {
      return param.path === route.path;
    };

    // 活跃标签样式
    const activeStyle = (tag) => {
      if (!isActive(tag)) return {};
      return {
        "background-color": "#409eff",
      };
    };

    const closeMenu = (tag, index) => {
      store.commit("SPLICE_ROUTES_CHECKED", index);
      // let length = store.state.tags.checkedRoutes.length,
      //   newCurrentRoute = store.state.tags.checkedRoutes[length - 1];
      //   debugger
      // router.push({ path: newCurrentRoute.path });
    };

    return {
      ...toRefs(state),
      isActive,
      activeStyle,
      closeMenu
    }
  },
  // methods: {
  //   closeMenu(tag, index) {
  //     const self = this;
  //     console.log(tag, index)
  //     // self.$store.commit("SPLICE_ROUTES_CHECKED", index);
  //     let length = self.$store.state.tags.checkedRoutes.length,
  //       newCurrentRoute = self.$store.state.tags.checkedRoutes[length - 1];
  //     self.$router.push({ path: newCurrentRoute.path });
  //     // self.$forceUpdate();
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.layout-tags-view {
  width: 100%;
  box-sizing: border-box;
  padding-left: 15px;
  height: 34px;
  border-bottom: 1px solid #d8dce5;
  -webkit-box-shadow: 0, 1px, 3px, 0, rgba(0, 0, 0, 0.12), 0, 0, 3px, 0, rgba(0, 0, 0, 0.04);
  box-shadow: 0, 1px, 3px, 0, rgba(0, 0, 0, 0.12), 0, 0, 3px, 0, rgba(0, 0, 0, 0.04);

  .tags-view-item {
    height: auto;
    margin-right: 6px;
    color: #000000;
    font-size: 12px;
    letter-spacing: 2px;

    .layout-router-link {
      width: fit-content;
      text-decoration: none;
      padding: 4px 8px;
      border: 1px solid #d8dce5;
    }

    .router-link-active {
      color: #ffffff;
      background-color: #409eff;

      &::before {
        content: "";
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: 2px;
      }
    }
  }
}
</style>
