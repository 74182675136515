<template>
<div class="app-wrapper">
  <!-- 判断是否登录 -->
  <template v-if="isLogin=='common'">
    <layout>
      <router-view />
    </layout>
  </template>
  <!-- 未登录显示登录页面 -->
  <template v-else-if="isLogin=='login'">
    <login />
  </template>
  <template v-else-if="isLogin=='bigIndex'">
    <big-index />
  </template>
  <template v-else>
    <not-found />
  </template>
</div>
</template>

<script>
import Layout from "layout";
import Login from "views/login/index.vue";
import { reactive, toRefs, watch, provide } from 'vue';
import { useRouter } from "vue-router";
import Cookies from "js-cookie";
import * as echarts from 'echarts'
import BigIndex from './views/home/bigIndex.vue';
import NotFound from './views/home/notFound.vue';
// import { useStore } from 'vuex';
export default {
  components: {
    Layout,
    Login,
    BigIndex,
    NotFound
  },
  setup() {
    const router = useRouter();
    // const store = useStore();
    // 全局注入echarts
    provide("echart", echarts)
    const state = reactive({
      // isLogin: true
      isLogin: "common" // common-正常页面  login-登录页面 bigIndex-大屏页面 notFound 401页面
    });

    // 监听当前路由
    watch(() => router.currentRoute.value, (newValue) => {
      // if (newValue.path != "/login" && newValue.path != "/bigIndex") {
      //   // 登录校验跳过开发环境
      //   if (process.env.NODE_ENV == "development") {
      //     state.isLogin = "common";
      //   } else {
      //     // 判断cookie中是否存在登录code
      //     if (Cookies.get("code")) {
      //       state.isLogin = "common";
      //     } else {
      //       state.isLogin = "login";
      //     }
      //   }
      // } else if (newValue.path == "/bigIndex") {
      //   state.isLogin = "bigIndex";
      // } else {
      //   state.isLogin = "login";
      // }
      // 如果是普通页面
      if (newValue.path != "/login" && newValue.path != "/bigIndex" && newValue.path != "/401") {
        if (process.env.NODE_ENV == "development") {
          state.isLogin = "common";
        } else {
          // 判断是否是登陆状态
          if (Cookies.get("code")) {
            // let userInfo = store.getters.userInfo;
            // // 判断是否有权限，没有权限的跳转至401页面
            // if ((userInfo.userNodes || []).length == 0) {
            //   state.isLogin = "notFound";
            // } else {
              state.isLogin = "common";
            // }
          } else {
            state.isLogin = "login";
          }
        }
        // 如果路由是大屏
      } else if (newValue.path == "/bigIndex") {
        state.isLogin = "bigIndex";
      } else if (newValue.path == "/401") {
        state.isLogin = "notFound";
      } else {
        state.isLogin = "login";
      }
    }, { immediate: true })

    return {
      ...toRefs(state)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  height: 100%;

  #page {
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: block;
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: rgba(14, 22, 37, 0.5);
    }
  }
}
</style>
